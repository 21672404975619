<template>
  <header class="header">
    <div class="header__main">
      <div class="container--small">
        <p class="main__title header__main-title">
          Гарантия на все виды выполняемых работ
        </p>
        <p class="main__text header__main-text">
          Мы в ответе за качество услуг, которые<br> предоставляются нашей
          компанией
        </p>
        <button
          class="btn btn--main"
          @click="openModal()"
        >
          Оставить заявку
        </button>
      </div>
    </div>
    <div class="header__results">
      <div class="header__results-item">
        <span
          class="results-number num"
          data-val="125"
        >125</span>
        <span class="results-text">Заявок<br>в день</span>
      </div>
      <div class="header__results-item results-item--special">
        <span
          class="results-number num"
          data-val="523"
        >523</span>
        <span class="results-text">Довольных<br>клиента</span>
      </div>
      <div class="header__results-item">
        <span
          class="results-number num"
          data-val="1239"
        >1239</span>
        <span class="results-text">Проверенных<br> сооружений</span>
      </div>
    </div>
    <pop-up />
  </header>
</template>

<script>
export default {
    name: 'HomeContent',
    components: {
    PopUp: () => import('@/components/PopUp')
    },
    filters: {
        addComma(val) {
            return val.toLocaleString()
        }
    },    
    mounted() {
        let valueDisplays = document.querySelectorAll('.num');
        let interval = 1000;
        valueDisplays.forEach(element => {
            let startValue = 0;
            let endValue = parseInt(element.getAttribute("data-val"));
            let duration = Math.floor(interval / endValue);
            let counter = setInterval(function(){
                startValue += 1;
                element.textContent = startValue;
                if (startValue == endValue){
                    clearInterval(counter);
                }
            }, duration);
        });
    },
    methods: {
        openModal() {
            document.querySelector('.popUp').classList.add('popUp__active')
            document.querySelector('body').classList.add('lock')
        },
        closeModal() {
            document.querySelector('.popUp').classList.remove('popUp__active')
            document.querySelector('body').classList.remove('lock')
        }
    }
}
</script>
